import CloudFun, { Application, MessageType, Policy } from '@cloudfun/core'
import model from './models'
import router from './router'
import sitemap from './sitemap'
import messages from './locales'

import App from './App.vue'
import globalComponents from './global-components'
import utils from './utils'
import './libs'
import Toastify from 'toastify-js'

import 'xe-utils'
import VXETable from 'vxe-table'
// import 'vxe-table/lib/style.css'
import './assets/sass/vxe-table/vxe-table.scss'

// Midone Theme
import './assets/sass/app.scss'

import { createI18n } from 'vue-i18n'

const app = new Application(
  App,
  model,
  new Policy(
    model,
    {
      router,
      sitemap,
      loginRoute: '/login',
      guard: (to, from, next) => {
        let expiry = false;
        if (model?.user && model.user.Type === 6) {
          expiry = model?.user.ExpiryTime && new Date(model.user.ExpiryTime) < new Date();
        }
        if (!model?.user.FirstChanged || expiry) {
          if (to.path !== '/' && to.path !== '/dashboard' && to.path !== '/login') { // 密碼過期轉跳
            alert(expiry ? "密碼已過期，請由右上選單進行密碼更新" : "新用戶請由右上選單進行密碼更新");
            next({ path: '/' });
          }
        }
        model.dispatch('configuration/read');
        next();
      }
    }
  ),
  createI18n({ locale: 'zh-Hant', fallbackLocale: 'zh-Hant', messages })
)

// 將 JS 攔截到的錯誤轉給 CloudfunVue Messenger
window.onerror = (message, source, lineno, colno) => {
  if (message === 'ResizeObserver loop limit exceeded') return false
  app.send('log', {
    createdTime: new Date(),
    type: MessageType.Error,
    content: app.user ? `[${app.user.Name}] \r\n${message}\r\n    at ${source}:${lineno}:${colno}` : `\r\n${message}\r\n    at ${source}:${lineno}:${colno}`
  })
  return true
}

// 將 Vue.js 攔截到的錯誤轉給 CloudfunVue Messenger
app.config.errorHandler = (error: any, instance, info) => {
  let content = app.user ? `[${app.user.Name}] ${info}: ` : `${info}: `
  if (typeof error === 'string') content += error
  else content += `${error.message}\r\n${error.stack}`
  console.log(error)
  app.send('log', {
    createdTime: new Date(),
    type: MessageType.Error,
    content
  })
}

// 設定信差中介程序
CloudFun.setMiddlewares({
  info: (message) => Toastify({
    text: `${typeof message === 'string' ? 'Information' : message.subject || 'Information'}: ${typeof message === 'string' ? message : message.content}`,
    close: true,
    gravity: 'bottom',
    position: 'left',
    style: { background: '#10b981' },
    stopOnFocus: true
  }).showToast(),
  warning: (message) => Toastify({
    text: `${typeof message === 'string' ? 'Warning' : message.subject || 'Warning'}: ${typeof message === 'string' ? message : message.content}`,
    close: true,
    gravity: 'bottom',
    position: 'left',
    style: { background: '#f5970f' },
    stopOnFocus: true
  }).showToast(),
  error: (message) => Toastify({
    text: `${typeof message === 'string' ? 'Error' : message.subject || 'Error'}: ${typeof message === 'string' ? message : message.content}`,
    duration: -1,
    close: true,
    gravity: 'bottom',
    position: 'left',
    style: { background: '#ef4444' },
    stopOnFocus: true
  }).showToast(),
  'application.error': (error) => console.log(error)
})

globalComponents(app)
utils(app)

app.use(VXETable)
VXETable.setup({ i18n: (key, args) => app.i18n.global.t(key, args) })

app.run('#app')
