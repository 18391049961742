import { PolicyRule } from "@cloudfun/core/dist/policy";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import ErrorPage from "./views/error-page/Main.vue";

const viewRotes: RouteRecordRaw[] = [
  // project views
  { path: "dashboard", component: () => import("./views/dashboard/Main.vue") },
  {
    path: "configuration",
    component: () => import("./views/configuration/Main.vue"),
    meta: { rule: "Configuration" }
  },
  {
    path: "action-log",
    component: () => import("./views/action-log/Main.vue"),
    meta: { rule: "ActionLog" }
  },
  {
    path: "mail-log",
    component: () => import("./views/mail-log/Main.vue"),
    meta: { rule: "MailLog" }
  },
  {
    path: "role",
    component: () => import("./views/role/Main.vue"),
    meta: { rule: "Role" }
  },
  {
    path: "user",
    component: () => import("./views/user/Main.vue"),
    meta: { rule: "User" }
  },
  {
    path: "user-wizard",
    component: () => import("./views/user-wizard/Main.vue"),
    meta: { rule: new PolicyRule("Role").and("User") }
  },
  {
    path: "template",
    component: () => import("./views/template/Main.vue"),
    meta: { rule: "Template" }
  },
  {
    path: "banner",
    component: () => import("./views/banner/Main.vue"),
    meta: { rule: "Banner" }
  },
  {
    path: "album",
    component: () => import("./views/album/Main.vue"),
    meta: { rule: "Album" }
  },
  { path: "attribute", component: () => import("./views/attribute/Main.vue") },
  {
    path: "introduction",
    component: () => import("./views/introduction/Main.vue"),
    meta: { rule: "About" }
  },
  {
    path: "charter",
    component: () => import("./views/charter/Main.vue"),
    meta: { rule: "About" }
  },
  {
    path: "join",
    component: () => import("./views/join/Main.vue"),
    meta: { rule: "About" }
  },
  {
    path: "organizational-system",
    component: () => import("./views/organizational-system/Main.vue"),
    meta: { rule: "About" }
  },
  { path: "field", component: () => import("./views/field/Main.vue") },
  {
    path: "industry-news",
    component: () => import("./views/industry-news/Main.vue"),
    meta: { rule: "IndustryNews" }
  },
  {
    path: "media",
    component: () => import("./views/media/Main.vue"),
    meta: { rule: "Media" }
  },
  {
    path: "book",
    component: () => import("./views/book/Main.vue"),
    meta: { rule: "Book" }
  },
  {
    path: "DM",
    component: () => import("./views/miiDM/Main.vue"),
    meta: { rule: "About" }
  },
  {
    path: "charter-file",
    component: () => import("./views/charter-file/Main.vue"),
    meta: { rule: "About" }
  },
  {
    path: "payment-file",
    component: () => import("./views/payment-file/Main.vue"),
    meta: { rule: "About" }
  },
  {
    path: "storeDM",
    component: () => import("./views/storeDM/Main.vue"),
    meta: { rule: "Book" }
  },
  {
    path: "keyword",
    component: () => import("./views/keyword/Main.vue"),
    meta: { rule: "Keyword" }
  },
  {
    path: "program",
    component: () => import("./views/document/Program.vue"),
    meta: { rule: "Program" }
  },
  {
    path: "document",
    component: () => import("./views/document/Main.vue"),
    meta: { rule: new PolicyRule("Document").or("DocumentView") }
  },
  {
    path: "speaker",
    component: () => import("./views/speaker/Main.vue"),
    meta: { rule: "Activity" }
  },
  {
    path: "qnr",
    component: () => import("./views/qnr/Main.vue"),
    meta: { rule: "Activity" }
  },
  {
    path: "document/report",
    component: () => import("./views/document/Report.vue"),
    meta: { rule: "DocumentWrite" }
  },
  {
    path: "document/proof",
    component: () => import("./views/document/Proof.vue"),
    meta: { rule: "DocumentProof" }
  },
  {
    path: "document/review",
    component: () => import("./views/document/Review.vue"),
    meta: { rule: "DocumentReview" }
  },
  {
    path: "member",
    component: () => import("./views/member/Main.vue"),
    meta: { rule: "Member" }
  },
  {
    path: "member-group",
    component: () => import("./views/member-group/Main.vue"),
    meta: { rule: "Member" }
  },
  {
    path: "industry-category",
    component: () => import("./views/industry-category/Main.vue"),
    meta: { rule: "Member" }
  },
  {
    path: "activity",
    component: () => import("./views/activity/Main.vue"),
    meta: { rule: "Activity" }
  },
  {
    path: "publication",
    component: () => import("./views/publication/Main.vue"),
    meta: { rule: "Publication" }
  },
  {
    path: "coupon-set",
    component: () => import("./views/coupon-set/Main.vue"),
    meta: { rule: "Coupon" }
  },
  {
    path: "rights",
    component: () => import("./views/rights/Main.vue"),
    meta: { rule: "Member" }
  },
  {
    path: "order",
    component: () => import("./views/order/Main.vue"),
    meta: { rule: "Order" }
  },
  {
    path: "team",
    component: () => import("./views/team/Main.vue"),
    meta: { rule: "Team" }
  },
  {
    path: "research-category",
    component: () => import("./views/research-category/Main.vue"),
    meta: { rule: "ResearchCategory" }
  },
  {
    path: "coupon-category",
    component: () => import("./views/coupon-category/Main.vue"),
    meta: { rule: "Coupon" }
  },
  {
    path: "consult-category",
    component: () => import("./views/consult-category/Main.vue"),
    meta: { rule: "Suggestion" }
  },
  {
    path: "suggestion",
    component: () => import("./views/suggestion/Main.vue"),
    meta: { rule: "Suggestion" }
  },
  {
    path: "epaper",
    component: () => import("./views/epaper/Main.vue"),
    meta: { rule: "Epaper" }
  },
  {
    path: "unit",
    component: () => import("./views/unit/Main.vue"),
    meta: { rule: "Activity" }
  },
  {
    path: "changing",
    component: () => import("./views/document/Changing.vue"),
    meta: { rule: "DocumentChange" }
  },
  {
    path: "feedback",
    component: () => import("./views/feedback/Main.vue"),
    meta: { rule: "Publication" }
  },
  {
    path: "utm-category",
    component: () => import("./views/utm-category/Main.vue"),
    meta: { rule: "Utm" }
  },
  {
    path: "utm",
    component: () => import("./views/utm/Main.vue"),
    meta: { rule: "Utm" }
  },
  {
    path: "certificate",
    component: () => import("./views/certificate/Main.vue"),
    meta: { rule: "Certificate" }
  },
  {
    path: "estate-category",
    component: () => import("./views/estate-category/Main.vue"),
    meta: { rule: "EstateCategory" }
  },
  {
    path: "technical-category",
    component: () => import("./views/technical-category/Main.vue"),
    meta: { rule: "TechnicalCategory" }
  },
  {
    path: "research-process-report",
    component: () => import("./views/research-process-report/Main.vue"),
    meta: { rule: "Statistics" }
  },
  {
    path: "keyword-statistics-report",
    component: () => import("./views/keyword-statistics-report/Main.vue"),
    meta: { rule: "Statistics" }
  },
  {
    path: "utm-statistics-report",
    component: () => import("./views/utm-statistics-report/Main.vue"),
    meta: { rule: "Statistics" }
  },
  {
    path: "visitor-statistics-report",
    component: () => import("./views/visitor-statistics-report/Main.vue"),
    meta: { rule: "Statistics" }
  },
  {
    path: "member-rights-statistics-report",
    component: () => import("./views/member-rights-statistics-report/Main.vue"),
    meta: { rule: "Statistics" }
  },
  // {
  //   path: "publication-statistics-report",
  //   component: () => import("./views/publication-statistics-report/Main.vue"),
  //   meta: { rule: "Statistics" }
  // },
  {
    path: "bullerin-statistics-report",
    component: () => import("./views/bullerin-statistics-report/Main.vue"),
    meta: { rule: "Statistics" }
  },
  {
    path: "advocacy-category",
    component: () => import("./views/advocacy-category/Main.vue"),
    meta: { rule: "BulletinCategory" }
  },
  {
    path: "information-category",
    component: () => import("./views/information-category/Main.vue"),
    meta: { rule: "BulletinCategory" }
  },
  {
    path: "news-category",
    component: () => import("./views/news-category/Main.vue"),
    meta: { rule: "BulletinCategory" }
  },
  {
    path: "news",
    component: () => import("./views/news/Main.vue"),
    meta: { rule: "Bulletin" }
  },
  {
    path: "information",
    component: () => import("./views/information/Main.vue"),
    meta: { rule: "Bulletin" }
  },
  {
    path: "advocacy",
    component: () => import("./views/advocacy/Main.vue"),
    meta: { rule: "Bulletin" }
  },
  {
    path: "question-info",
    component: () => import("./views/question-info/Main.vue"),
    meta: { rule: "QuestionInfo" }
  },
  {
    path: "question-info-category",
    component: () => import("./views/question-info-category/Main.vue"),
    meta: { rule: "QuestionInfoCategory" }
  },
  {
    path: "director-section",
    component: () => import("./views/director-section/Main.vue"),
    meta: { rule: "DirectorSection" }
  },
  {
    path: "director-section-category",
    component: () => import("./views/director-section-category/Main.vue"),
    meta: { rule: "DirectorSectionCategory" }
  },
  {
    path: "meeting-news",
    component: () => import("./views/meeting-news/Main.vue"),
    meta: { rule: "Bulletin" }
  },
  {
    path: "meeting-news-category",
    component: () => import("./views/meeting-news-category/Main.vue"),
    meta: { rule: "BulletinCategory" }
  },
  // mid-one views
  {
    path: "midone-dashboard",
    component: () => import("./midone/views/dashboard/Main.vue")
  },
  {
    path: "midone-inbox",
    component: () => import("./midone/views/inbox/Main.vue")
  },
  {
    path: "midone-file-manager",
    component: () => import("./midone/views/file-manager/Main.vue")
  },
  {
    path: "midone-point-of-sale",
    component: () => import("./midone/views/point-of-sale/Main.vue")
  },
  {
    path: "midone-chat",
    component: () => import("./midone/views/chat/Main.vue")
  },
  {
    path: "midone-post",
    component: () => import("./midone/views/post/Main.vue")
  },
  {
    path: "midone-crud-data-list",
    component: () => import("./midone/views/crud-data-list/Main.vue")
  },
  {
    path: "midone-crud-form",
    component: () => import("./midone/views/crud-form/Main.vue")
  },
  {
    path: "midone-users-layout-1",
    component: () => import("./midone/views/users-layout-1/Main.vue")
  },
  {
    path: "midone-users-layout-2",
    component: () => import("./midone/views/users-layout-2/Main.vue")
  },
  {
    path: "midone-users-layout-3",
    component: () => import("./midone/views/users-layout-3/Main.vue")
  },
  {
    path: "midone-profile-overview-1",
    component: () => import("./midone/views/profile-overview-1/Main.vue")
  },
  {
    path: "midone-profile-overview-2",
    component: () => import("./midone/views/profile-overview-2/Main.vue")
  },
  {
    path: "midone-profile-overview-3",
    component: () => import("./midone/views/profile-overview-3/Main.vue")
  },
  {
    path: "midone-wizard-layout-1",
    component: () => import("./midone/views/wizard-layout-1/Main.vue")
  },
  {
    path: "midone-wizard-layout-2",
    component: () => import("./midone/views/wizard-layout-2/Main.vue")
  },
  {
    path: "midone-wizard-layout-3",
    component: () => import("./midone/views/wizard-layout-3/Main.vue")
  },
  {
    path: "midone-blog-layout-1",
    component: () => import("./midone/views/blog-layout-1/Main.vue")
  },
  {
    path: "midone-blog-layout-2",
    component: () => import("./midone/views/blog-layout-2/Main.vue")
  },
  {
    path: "midone-blog-layout-3",
    component: () => import("./midone/views/blog-layout-3/Main.vue")
  },
  {
    path: "midone-pricing-layout-1",
    component: () => import("./midone/views/pricing-layout-1/Main.vue")
  },
  {
    path: "midone-pricing-layout-2",
    component: () => import("./midone/views/pricing-layout-2/Main.vue")
  },
  {
    path: "midone-invoice-layout-1",
    component: () => import("./midone/views/invoice-layout-1/Main.vue")
  },
  {
    path: "midone-invoice-layout-2",
    component: () => import("./midone/views/invoice-layout-2/Main.vue")
  },
  {
    path: "midone-faq-layout-1",
    component: () => import("./midone/views/faq-layout-1/Main.vue")
  },
  {
    path: "midone-faq-layout-2",
    component: () => import("./midone/views/faq-layout-2/Main.vue")
  },
  {
    path: "midone-faq-layout-3",
    component: () => import("./midone/views/faq-layout-3/Main.vue")
  },
  {
    path: "midone-update-profile",
    component: () => import("./midone/views/update-profile/Main.vue")
  },
  {
    path: "midone-change-password",
    component: () => import("./midone/views/change-password/Main.vue")
  },
  {
    path: "midone-regular-table",
    component: () => import("./midone/views/regular-table/Main.vue")
  },
  {
    path: "midone-tabulator",
    component: () => import("./midone/views/tabulator/Main.vue")
  },
  {
    path: "midone-accordion",
    component: () => import("./midone/views/accordion/Main.vue")
  },
  {
    path: "midone-button",
    component: () => import("./midone/views/button/Main.vue")
  },
  {
    path: "midone-modal",
    component: () => import("./midone/views/modal/Main.vue")
  },
  {
    path: "midone-alert",
    component: () => import("./midone/views/alert/Main.vue")
  },
  {
    path: "midone-progress-bar",
    component: () => import("./midone/views/progress-bar/Main.vue")
  },
  {
    path: "midone-tooltip",
    component: () => import("./midone/views/tooltip/Main.vue")
  },
  {
    path: "midone-dropdown",
    component: () => import("./midone/views/dropdown/Main.vue")
  },
  {
    path: "midone-toast",
    component: () => import("./midone/views/toast/Main.vue")
  },
  {
    path: "midone-typography",
    component: () => import("./midone/views/typography/Main.vue")
  },
  {
    path: "midone-icon",
    component: () => import("./midone/views/icon/Main.vue")
  },
  {
    path: "midone-loading-icon",
    component: () => import("./midone/views/loading-icon/Main.vue")
  },
  {
    path: "midone-regular-form",
    component: () => import("./midone/views/regular-form/Main.vue")
  },
  {
    path: "midone-datepicker",
    component: () => import("./midone/views/datepicker/Main.vue")
  },
  {
    path: "midone-tail-select",
    component: () => import("./midone/views/tail-select/Main.vue")
  },
  {
    path: "midone-file-upload",
    component: () => import("./midone/views/file-upload/Main.vue")
  },
  {
    path: "midone-wysiwyg-editor",
    component: () => import("./midone/views/wysiwyg-editor/Main.vue")
  },
  {
    path: "midone-validation",
    component: () => import("./midone/views/validation/Main.vue")
  },
  {
    path: "midone-chart",
    component: () => import("./midone/views/chart/Main.vue")
  },
  {
    path: "midone-slider",
    component: () => import("./midone/views/slider/Main.vue")
  },
  {
    path: "midone-image-zoom",
    component: () => import("./midone/views/image-zoom/Main.vue")
  }
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/side-menu/Main.vue")
  },
  {
    path: "/simple-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/simple-menu/Main.vue")
  },
  {
    path: "/top-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/top-menu/Main.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/login/Main.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/register/Main.vue")
  },
  { path: "/error-page", name: "error-page", component: ErrorPage },
  { path: "/:pathMatch(.*)*", component: ErrorPage }
];

export default createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});
